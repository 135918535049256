import React from "react"

export const Dev_SVG = () => (
  <svg
    className="home_title home_dev"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 77 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.90024 22.024H9.52024C10.7442 22.024 11.8362 21.832 12.7962 21.448C13.7562 21.064 14.5602 20.536 15.2082 19.864C15.8802 19.192 16.3842 18.4 16.7202 17.488C17.0802 16.552 17.2602 15.532 17.2602 14.428C17.2602 13.348 17.0802 12.34 16.7202 11.404C16.3602 10.468 15.8442 9.66402 15.1722 8.99202C14.5242 8.32002 13.7202 7.79202 12.7602 7.40802C11.8002 7.02402 10.7202 6.83202 9.52024 6.83202H7.90024V22.024ZM0.844238 0.856018H11.2842C13.1322 0.856018 14.8602 1.22802 16.4682 1.97202C18.1002 2.71602 19.5162 3.71202 20.7162 4.96002C21.9402 6.18402 22.9002 7.62402 23.5962 9.28002C24.2922 10.912 24.6402 12.628 24.6402 14.428C24.6402 16.204 24.2922 17.92 23.5962 19.576C22.9242 21.208 21.9762 22.648 20.7522 23.896C19.5522 25.144 18.1362 26.14 16.5042 26.884C14.8962 27.628 13.1562 28 11.2842 28H0.844238V0.856018Z"
      stroke="#0092C7"
    />
    <path
      d="M44.589 6.83202H36.201V11.368H44.121V17.344H36.201V22.024H44.589V28H29.145V0.856018H44.589V6.83202Z"
      stroke="#0092C7"
    />
    <path
      d="M54.5003 0.856018L61.0523 17.38L67.6403 0.856018H75.3083L63.8603 28H58.2443L46.8323 0.856018H54.5003Z"
      stroke="#0092C7"
    />
  </svg>
)

export const Photo_SVG = () => (
  <svg
    className="home_title home_photo"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 137 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.90024 14.312H10.2402C12.8322 14.312 14.1282 13.184 14.1282 10.928C14.1282 8.67201 12.8322 7.54401 10.2402 7.54401H7.90024V14.312ZM7.90024 29H0.844238V1.85601H12.0762C15.1242 1.85601 17.4522 2.64801 19.0602 4.23201C20.6922 5.81601 21.5082 8.04801 21.5082 10.928C21.5082 13.808 20.6922 16.04 19.0602 17.624C17.4522 19.208 15.1242 20 12.0762 20H7.90024V29Z"
      stroke="#0092C7"
    />
    <path
      d="M32.2987 12.332H42.4867V1.85601H49.5427V29H42.4867V17.804H32.2987V29H25.2427V1.85601H32.2987V12.332Z"
      stroke="#0092C7"
    />
    <path
      d="M61.387 15.428C61.387 16.508 61.591 17.504 61.999 18.416C62.407 19.328 62.959 20.12 63.655 20.792C64.351 21.464 65.155 21.992 66.067 22.376C67.003 22.736 67.987 22.916 69.019 22.916C70.051 22.916 71.023 22.736 71.935 22.376C72.871 21.992 73.687 21.464 74.383 20.792C75.103 20.12 75.667 19.328 76.075 18.416C76.483 17.504 76.687 16.508 76.687 15.428C76.687 14.348 76.483 13.352 76.075 12.44C75.667 11.528 75.103 10.736 74.383 10.064C73.687 9.39201 72.871 8.87601 71.935 8.51601C71.023 8.13201 70.051 7.94001 69.019 7.94001C67.987 7.94001 67.003 8.13201 66.067 8.51601C65.155 8.87601 64.351 9.39201 63.655 10.064C62.959 10.736 62.407 11.528 61.999 12.44C61.591 13.352 61.387 14.348 61.387 15.428ZM54.007 15.428C54.007 13.412 54.379 11.54 55.123 9.81201C55.867 8.06001 56.899 6.53601 58.219 5.24001C59.539 3.94401 61.111 2.93601 62.935 2.21601C64.783 1.47201 66.811 1.10001 69.019 1.10001C71.203 1.10001 73.219 1.47201 75.067 2.21601C76.915 2.93601 78.499 3.94401 79.819 5.24001C81.163 6.53601 82.207 8.06001 82.951 9.81201C83.695 11.54 84.067 13.412 84.067 15.428C84.067 17.444 83.695 19.328 82.951 21.08C82.207 22.808 81.163 24.32 79.819 25.616C78.499 26.912 76.915 27.932 75.067 28.676C73.219 29.396 71.203 29.756 69.019 29.756C66.811 29.756 64.783 29.396 62.935 28.676C61.111 27.932 59.539 26.912 58.219 25.616C56.899 24.32 55.867 22.808 55.123 21.08C54.379 19.328 54.007 17.444 54.007 15.428Z"
      stroke="#0092C7"
    />
    <path
      d="M98.5387 7.83201V29H91.4827V7.83201H85.6867V1.85601H104.335V7.83201H98.5387Z"
      stroke="#0092C7"
    />
    <path
      d="M113.383 15.428C113.383 16.508 113.587 17.504 113.995 18.416C114.403 19.328 114.955 20.12 115.651 20.792C116.347 21.464 117.151 21.992 118.063 22.376C118.999 22.736 119.983 22.916 121.015 22.916C122.047 22.916 123.019 22.736 123.931 22.376C124.867 21.992 125.683 21.464 126.379 20.792C127.099 20.12 127.663 19.328 128.071 18.416C128.479 17.504 128.683 16.508 128.683 15.428C128.683 14.348 128.479 13.352 128.071 12.44C127.663 11.528 127.099 10.736 126.379 10.064C125.683 9.39201 124.867 8.87601 123.931 8.51601C123.019 8.13201 122.047 7.94001 121.015 7.94001C119.983 7.94001 118.999 8.13201 118.063 8.51601C117.151 8.87601 116.347 9.39201 115.651 10.064C114.955 10.736 114.403 11.528 113.995 12.44C113.587 13.352 113.383 14.348 113.383 15.428ZM106.003 15.428C106.003 13.412 106.375 11.54 107.119 9.81201C107.863 8.06001 108.895 6.53601 110.215 5.24001C111.535 3.94401 113.107 2.93601 114.931 2.21601C116.779 1.47201 118.807 1.10001 121.015 1.10001C123.199 1.10001 125.215 1.47201 127.063 2.21601C128.911 2.93601 130.495 3.94401 131.815 5.24001C133.159 6.53601 134.203 8.06001 134.947 9.81201C135.691 11.54 136.063 13.412 136.063 15.428C136.063 17.444 135.691 19.328 134.947 21.08C134.203 22.808 133.159 24.32 131.815 25.616C130.495 26.912 128.911 27.932 127.063 28.676C125.215 29.396 123.199 29.756 121.015 29.756C118.807 29.756 116.779 29.396 114.931 28.676C113.107 27.932 111.535 26.912 110.215 25.616C108.895 24.32 107.863 22.808 107.119 21.08C106.375 19.328 106.003 17.444 106.003 15.428Z"
      stroke="#0092C7"
    />
  </svg>
)

export const Write_SVG = () => (
  <svg
    className="home_title home_write"
    viewBox="0 0 119 29"
    preserveAspectRatio="xMidYMid meet"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.38 0.856018L13.06 18.388L18.82 0.856018H24.436L30.196 18.388L34.876 0.856018H42.256L34.156 28H26.956L21.628 12.268L16.3 28H9.1L1 0.856018H8.38Z"
      stroke="#0092C7"
    />
    <path
      d="M52.1734 12.988H53.5054C54.8974 12.988 55.9654 12.7 56.7094 12.124C57.4534 11.548 57.8254 10.72 57.8254 9.64002C57.8254 8.56002 57.4534 7.73202 56.7094 7.15602C55.9654 6.58002 54.8974 6.29202 53.5054 6.29202H52.1734V12.988ZM67.6894 28H58.9054L52.1734 17.56V28H45.1174V0.856018H56.0974C57.6094 0.856018 58.9294 1.08402 60.0574 1.54002C61.1854 1.97202 62.1094 2.57202 62.8294 3.34002C63.5734 4.10802 64.1254 4.99602 64.4854 6.00402C64.8694 7.01202 65.0614 8.09202 65.0614 9.24402C65.0614 11.308 64.5574 12.988 63.5494 14.284C62.5654 15.556 61.1014 16.42 59.1574 16.876L67.6894 28Z"
      stroke="#0092C7"
    />
    <path d="M77.6969 0.856018V28H70.6409V0.856018H77.6969Z" stroke="#0092C7" />
    <path
      d="M93.6634 6.83202V28H86.6074V6.83202H80.8114V0.856018H99.4594V6.83202H93.6634Z"
      stroke="#0092C7"
    />
    <path
      d="M118.042 6.83202H109.654V11.368H117.574V17.344H109.654V22.024H118.042V28H102.598V0.856018H118.042V6.83202Z"
      stroke="#0092C7"
    />
  </svg>
)

export const Resume_SVG = () => (
  <svg
    className="home_title home_resume"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 153 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.90024 13.988H9.23224C10.6242 13.988 11.6922 13.7 12.4362 13.124C13.1802 12.548 13.5522 11.72 13.5522 10.64C13.5522 9.56001 13.1802 8.73201 12.4362 8.15601C11.6922 7.58001 10.6242 7.29201 9.23224 7.29201H7.90024V13.988ZM23.4162 29H14.6322L7.90024 18.56V29H0.844238V1.85601H11.8242C13.3362 1.85601 14.6562 2.08401 15.7842 2.54001C16.9122 2.97201 17.8362 3.57201 18.5562 4.34001C19.3002 5.10801 19.8522 5.99601 20.2122 7.00401C20.5962 8.01201 20.7882 9.09201 20.7882 10.244C20.7882 12.308 20.2842 13.988 19.2762 15.284C18.2922 16.556 16.8282 17.42 14.8842 17.876L23.4162 29Z"
      stroke="#0092C7"
    />
    <path
      d="M41.8117 7.83201H33.4237V12.368H41.3437V18.344H33.4237V23.024H41.8117V29H26.3677V1.85601H41.8117V7.83201Z"
      stroke="#0092C7"
    />
    <path
      d="M61.8029 8.80401C61.0349 8.18001 60.2669 7.72401 59.4989 7.43601C58.7309 7.12401 57.9869 6.96801 57.2669 6.96801C56.3549 6.96801 55.6109 7.18401 55.0349 7.61601C54.4589 8.04801 54.1709 8.61201 54.1709 9.30801C54.1709 9.78801 54.3149 10.184 54.6029 10.496C54.8909 10.808 55.2629 11.084 55.7189 11.324C56.1989 11.54 56.7269 11.732 57.3029 11.9C57.9029 12.068 58.4909 12.248 59.0669 12.44C61.3709 13.208 63.0509 14.24 64.1069 15.536C65.1869 16.808 65.7269 18.476 65.7269 20.54C65.7269 21.932 65.4869 23.192 65.0069 24.32C64.5509 25.448 63.8669 26.42 62.9549 27.236C62.0669 28.028 60.9629 28.64 59.6429 29.072C58.3469 29.528 56.8709 29.756 55.2149 29.756C51.7829 29.756 48.6029 28.736 45.6749 26.696L48.6989 21.008C49.7549 21.944 50.7989 22.64 51.8309 23.096C52.8629 23.552 53.8829 23.78 54.8909 23.78C56.0429 23.78 56.8949 23.516 57.4469 22.988C58.0229 22.46 58.3109 21.86 58.3109 21.188C58.3109 20.78 58.2389 20.432 58.0949 20.144C57.9509 19.832 57.7109 19.556 57.3749 19.316C57.0389 19.052 56.5949 18.812 56.0429 18.596C55.5149 18.38 54.8669 18.14 54.0989 17.876C53.1869 17.588 52.2869 17.276 51.3989 16.94C50.5349 16.58 49.7549 16.112 49.0589 15.536C48.3869 14.96 47.8349 14.24 47.4029 13.376C46.9949 12.488 46.7909 11.372 46.7909 10.028C46.7909 8.68401 47.0069 7.47201 47.4389 6.39201C47.8949 5.28801 48.5189 4.35201 49.3109 3.58401C50.1269 2.79201 51.1109 2.18001 52.2629 1.74801C53.4389 1.31601 54.7469 1.10001 56.1869 1.10001C57.5309 1.10001 58.9349 1.29201 60.3989 1.67601C61.8629 2.03601 63.2669 2.57601 64.6109 3.29601L61.8029 8.80401Z"
      stroke="#0092C7"
    />
    <path
      d="M77.2284 1.85601V16.616C77.2284 17.408 77.2524 18.224 77.3004 19.064C77.3724 19.88 77.5524 20.624 77.8404 21.296C78.1524 21.968 78.6324 22.52 79.2804 22.952C79.9284 23.36 80.8404 23.564 82.0164 23.564C83.1924 23.564 84.0924 23.36 84.7164 22.952C85.3644 22.52 85.8444 21.968 86.1564 21.296C86.4684 20.624 86.6484 19.88 86.6964 19.064C86.7684 18.224 86.8044 17.408 86.8044 16.616V1.85601H93.8244V17.588C93.8244 21.812 92.8524 24.896 90.9084 26.84C88.9884 28.784 86.0244 29.756 82.0164 29.756C78.0084 29.756 75.0324 28.784 73.0884 26.84C71.1444 24.896 70.1724 21.812 70.1724 17.588V1.85601H77.2284Z"
      stroke="#0092C7"
    />
    <path
      d="M98.3038 29L102.912 1.85601H109.896L115.332 16.328L120.732 1.85601H127.716L132.324 29H125.304L122.964 13.376L116.556 29H113.748L107.664 13.376L105.324 29H98.3038Z"
      stroke="#0092C7"
    />
    <path
      d="M152.237 7.83201H143.849V12.368H151.769V18.344H143.849V23.024H152.237V29H136.793V1.85601H152.237V7.83201Z"
      stroke="#0092C7"
    />
  </svg>
)
